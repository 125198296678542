import type { Dictionary } from "@reduxjs/toolkit";
import {
	DeviceActivationStateType,
	type DeviceActivationStateTypeMap,
	type DevicePlanResponse,
	DeviceRecord, // eslint-disable-line
	type DeviceSettingsResponse, // eslint-disable-line
	type DeviceStatusResponse,
	IdentityRecord, // eslint-disable-line
} from "@somewear/api"; // eslint-disable-line
import { selectIdentityEntities, selectWorkspaceAssetEntities } from "@somewear/asset";
import { selectActiveOrganizationId, selectActiveWorkspaceId } from "@somewear/auth";
import {
	isBorrowedDevice,
	selectDeviceEntities,
	selectDeviceTransferEntities,
	selectQueuedDeviceNameChangesEntities,
} from "@somewear/device";
import type { IIdentity, IWorkspaceAsset } from "@somewear/model";
import {
	DeviceActivationChange,
	getDeviceModelName,
	getDictionaryValue,
	optionalBoolEnabled,
	timestampToMoment,
} from "@somewear/model";
import { subscriptionPlanIsUnlimited } from "@somewear/subscription";
import { selectWorkspaceEntities } from "@somewear/workspace";
import { createSelector } from "reselect";

import { selectQueuedDeviceActivationChangeEntities } from "./queuedDeviceActivationChangeSlice";
import type { SettingsState } from "./settings.state";
import { ChangeableFields } from "./settingsSlice";

const deviceStatusSelector = (state: SettingsState) => {
	return state.settings.deviceStatus;
};

const deviceSettingsSelector = (state: SettingsState) => {
	return state.settings.deviceSettings;
};

export const selectDeviceSettingsPending = (state: SettingsState) => {
	return state.settings.deviceSettingsPending;
};

const selectDeviceUserIds = (state: SettingsState) => state.settings.deviceUserIds;

export const selectShowArchived = (state: SettingsState) => state.settings.showArchived;

export const selectShowBorrowed = (state: SettingsState) => state.settings.showBorrowed;

export interface DeviceStatusSummary {
	serial: string;
	imei: string;
	assetName: string;
	displayName: string;
	type: string;
	assignedAsset?: IIdentity;
	settings?: DeviceSettingsResponse.AsObject;
	lastActive: DeviceStatusResponse.AsObject["lastHeartbeat"];
	lastLocation: DeviceStatusResponse.AsObject["timestamp"];
	battery: DeviceStatusResponse.AsObject["battery"];
	originalWorkspaceId: string;
	model: DeviceRecord.AsObject["model"];
	workspaceId?: string;
	workspaceName?: string;
	identityId?: string;
	isBorrowed: boolean;
	organizationId?: string;
	gateway: DeviceRecord.AsObject["gateway"];
	plan?: DevicePlanResponse.AsObject;
	kbUsed?: number;
	kbIncluded?: number;
	hasDataUsage?: boolean;
	changedFields: ChangeableFields[];
}

export const selectDeviceAssetsDict = createSelector(
	[selectDeviceUserIds, selectWorkspaceAssetEntities],
	(deviceAssetIds, assetDict) => {
		const deviceAssetDict: Dictionary<IWorkspaceAsset> = {};
		Object.values(deviceAssetIds)
			.mapNotNull((it) => it)
			.forEach((id) => {
				deviceAssetDict[id] = getDictionaryValue(assetDict, id);
			});
		return deviceAssetDict;
	}
);

export const selectDeviceSummary = createSelector(
	[
		selectDeviceEntities,
		deviceStatusSelector,
		deviceSettingsSelector,
		selectDeviceSettingsPending,
		selectQueuedDeviceNameChangesEntities,
		selectDeviceTransferEntities,
		selectQueuedDeviceActivationChangeEntities,
		selectDeviceAssetsDict,
		selectIdentityEntities,
		selectWorkspaceEntities,
		selectActiveOrganizationId,
	],
	(
		deviceDict,
		deviceStatus,
		deviceSettings,
		deviceSettingsPending,
		queuedDeviceNameChangesDict,
		deviceTransferDict,
		queuedDeviceActivationChangesDict,
		assetsDict,
		identityDict,
		workspaceDict,
		activeOrganizationId
	) => {
		if (Object.keys(deviceDict).isEmpty()) return [];

		const mergedSettings = Object.assign({ ...deviceSettings }, deviceSettingsPending);

		return Object.values(deviceDict)
			.mapNotNull((it) => it)
			.map((device) => {
				const serial = device.serial;

				const changedFields: ChangeableFields[] = [];

				const transfer = getDictionaryValue(deviceTransferDict, serial);
				const activationChange = getDictionaryValue(
					queuedDeviceActivationChangesDict,
					serial
				);
				const assignedAssetAccount = getDictionaryValue(assetsDict, device.userAccountId);
				const assignedAsset = getDictionaryValue(
					identityDict,
					assignedAssetAccount?.identityId
				);
				const transferAsset = getDictionaryValue(identityDict, transfer?.targetIdentityId);

				const deviceIdentity = getDictionaryValue(identityDict, device.identityId);
				const nameChange = getDictionaryValue(queuedDeviceNameChangesDict, serial);

				if (nameChange !== undefined) {
					changedFields.push(ChangeableFields.Name);
				}
				const displayName = nameChange?.name ?? deviceIdentity?.fullName ?? serial;

				if (transfer && transfer.targetWorkspaceId !== device.workspaceId) {
					changedFields.push(ChangeableFields.Workspace);
				}

				if (transfer && transfer.targetIdentityId !== assignedAsset?.id) {
					changedFields.push(ChangeableFields.User);
				}

				if (activationChange && activationChange.change !== DeviceActivationChange.NONE) {
					changedFields.push(ChangeableFields.ActivationStatus);
				}

				[
					ChangeableFields.Battery,
					ChangeableFields.Altitude,
					ChangeableFields.TrackingInterval,
					ChangeableFields.Backhaul,
				].forEach((field) => {
					if (settingHasChanged(field)) {
						changedFields.push(field);
					}
				});

				const workspaceId = transfer?.targetWorkspaceId ?? device.workspaceId;
				const workspace = getDictionaryValue(workspaceDict, workspaceId);
				const isBorrowed = isBorrowedDevice(device, workspace, activeOrganizationId);
				const status = deviceStatus[serial];
				const assignedAssetFormatted =
					transfer?.targetIdentityId || transfer?.targetIdentityId === ""
						? transferAsset
						: assignedAsset;

				const summary: DeviceStatusSummary = {
					assignedAsset: assignedAssetFormatted,
					assetName:
						assignedAssetFormatted?.type === IdentityRecord.Type.DEVICE
							? "-"
							: assignedAssetFormatted?.fullName ?? "-",
					changedFields,
					displayName,
					serial,
					isBorrowed,
					lastActive: status?.lastHeartbeat,
					lastLocation: status?.timestamp,
					battery: status?.battery,
					settings: mergedSettings[serial],
					model: device.model,
					type: getDeviceModelName(device),
					imei: device.imei,
					identityId: device.identityId,
					originalWorkspaceId: device.workspaceId,
					organizationId: device.organizationId,
					gateway: device.gateway,
					plan: device.plan,
					kbUsed: device.kbUsed,
					kbIncluded: device.kbIncluded,
					hasDataUsage: device.hasDataUsage,
					workspaceId,
					workspaceName: workspace?.name,
				};

				return summary;

				function settingHasChanged(field: ChangeableFields) {
					const { settings: pendingSettings } = deviceSettingsPending[serial] ?? {};
					const { settings: originalSettings } = deviceSettings[serial] ?? {};

					if (!pendingSettings) return false;

					const vals: Partial<Record<ChangeableFields, boolean>> = {
						[ChangeableFields.Altitude]:
							pendingSettings?.enableAltitudeReporting !==
							originalSettings?.enableAltitudeReporting,
						[ChangeableFields.Battery]:
							pendingSettings?.batteryReporting !==
							originalSettings?.batteryReporting,
						[ChangeableFields.TrackingInterval]:
							pendingSettings?.trackingInterval !==
								originalSettings?.trackingInterval ||
							pendingSettings?.gpsInterval !== originalSettings?.gpsInterval,
						[ChangeableFields.Backhaul]:
							optionalBoolEnabled(pendingSettings?.enableBackhaul) !==
							optionalBoolEnabled(originalSettings?.enableBackhaul),
					};

					return vals[field] ?? false;
				}
			})
			.sort((a, b) => {
				return a.serial.localeCompare(b.serial);
			});
	}
);

export const selectDeviceSummaryForActiveWorkspace = createSelector(
	[selectDeviceSummary, selectActiveWorkspaceId],
	(summaryList, workspaceId) => {
		return summaryList.filter((it) => it.originalWorkspaceId === workspaceId);
	}
);

export const selectDeviceSummaryForActiveOrganization = createSelector(
	[selectDeviceSummary, selectActiveOrganizationId],
	(summaryList, organizationId) => {
		return summaryList.filter((it) => it.organizationId === organizationId);
	}
);

export const selectPendingDeviceChangeCount = createSelector(
	[selectDeviceSummary],
	(summaryList) => {
		return summaryList.reduce((acc, item) => {
			if (item.changedFields.length > 0) {
				acc += 1;
			}
			return acc;
		}, 0);
	}
);

const getActivationStateLabel = (
	activationState?: DeviceActivationStateTypeMap[keyof DeviceActivationStateTypeMap]
): string | undefined => {
	if (activationState === DeviceActivationStateType.STATUS_ACTIVE) {
		return "Active";
	} else if (activationState === DeviceActivationStateType.STATUS_DISABLED) {
		return "Disabled";
	} else if (
		activationState === DeviceActivationStateType.STATUS_SUSPENDED ||
		activationState === DeviceActivationStateType.STATUS_SUSPENDING
	) {
		return "Suspended";
	} else if (activationState === DeviceActivationStateType.STATUS_INACTIVE) {
		return "Inactive";
	}
	return undefined;
};

export const selectSelectedDeviceDict = (state: SettingsState) => {
	return state.settings.selectedDeviceDict;
};

export const selectDeviceDictEnabledSerials = createSelector(
	[selectSelectedDeviceDict],
	(selectedDeviceDict) => {
		return Object.entries(selectedDeviceDict).reduce(
			(acc, [key, value]) => (value ? [...acc, key] : acc),
			[] as string[]
		);
	}
);

export const selectDeviceCsvDataForActiveOrganization = createSelector(
	[selectDeviceEntities, selectDeviceSummary, selectActiveOrganizationId],
	(deviceDict, summaryList, activeOrganizationId) => {
		return summaryList
			.filter((it) => it.organizationId === activeOrganizationId)
			.mapNotNull((it, idx) => {
				const device = getDictionaryValue(deviceDict, it.serial);
				if (device === undefined) return undefined;

				const supportsDeviceUsage =
					device.gateway === DeviceRecord.Gateway.COMMERCIAL &&
					device.plan !== undefined &&
					device.model !== DeviceRecord.Model.SHOUTNANO &&
					!subscriptionPlanIsUnlimited(device.plan.subscriptionPlan);

				const dataUsage = supportsDeviceUsage
					? {
							"SATELLITE DATA USED (kBs)": supportsDeviceUsage
								? device.kbUsed
								: undefined,
					  }
					: {};

				const address =
					idx === 0
						? {
								"IP ADDRESS": "sbd.somewear.app:10800",
						  }
						: undefined;

				return {
					"SERIAL #s": it.serial,
					"IMEI #s": it.imei,
					TYPE: getDeviceModelName(device),
					"LAST ACTIVE": it?.lastActive
						? timestampToMoment(it?.lastActive).toDate()
						: "--",
					"SATELLITE STATUS": getActivationStateLabel(device.plan?.activationState),
					...dataUsage,
					...address,
				};
			});
	}
);

export const selectAllDevicesSelected = createSelector(
	[selectDeviceEntities, selectDeviceDictEnabledSerials],
	(deviceDict, enabledSerials) => {
		return enabledSerials.length === Object.keys(deviceDict).length;
	}
);

export const selectBulkEditingDevices = createSelector(
	[selectDeviceDictEnabledSerials],
	(enabledSerials) => {
		return enabledSerials.length > 0;
	}
);
